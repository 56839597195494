import React, { useEffect, useRef } from 'react'
import logo from './logo.svg'
import './App.css';

const OAUTH_REDIRECT_URL = process.env.REACT_APP_CAMPAIGN_PAGE_URL


function App() {
  return (
    <div className="App">
      <iframe 
      src={OAUTH_REDIRECT_URL}
      className="iframe"
      ></iframe>
    </div>
  );
}

export default App;
